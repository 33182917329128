<template>
  <div>
    <userHeader></userHeader>
    <router-view />
    <asideIcon></asideIcon>
  </div>
</template>

<script>
import asideIcon from '@/components/asideIcon'
import userHeader from '@/components/header/userHeader'
export default {
  components:{
    asideIcon,
    userHeader,
  },
  data() {
    return {
      
    };
  },
  created() {
   
  },
  methods: {
   
   
  },
};
</script>

<style lang="scss" scoped>


</style>
